import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { BasicCategory, MarketplaceInfo, SocialConnectedProfile } from '../api/v1-api/helpers';
import useRequest from 'src/utils/hooks/useRequest';
import { useAuth } from 'src/utils/auth';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import useSingleToast from 'src/utils/hooks/toast/useSingleToast';
import * as Sentry from '@sentry/nextjs';

type MarketplaceContextProps = {
  marketplace: MarketplaceInfo | null;
  setMarketplace: React.Dispatch<React.SetStateAction<MarketplaceInfo | null>>;
  hostname: string | null;
  setHostname: React.Dispatch<React.SetStateAction<string | null>>;
  marketplaceSlug: string | null;
  setMarketplaceSlug: React.Dispatch<React.SetStateAction<string | null>>;
  urlBase: string;
  setUrlBase: React.Dispatch<React.SetStateAction<string>>;
  apexDomain: string | null;
  setApexDomain: React.Dispatch<React.SetStateAction<string | null>>;
  setLogoIsCircle: React.Dispatch<React.SetStateAction<boolean>>;
  logoIsCircle: boolean;
  supportEmail: string | null;
  setSupportEmail: React.Dispatch<React.SetStateAction<string | null>>;
  categories: BasicCategory[];
  setCategories: React.Dispatch<React.SetStateAction<BasicCategory[]>>;
  fetchCategories: () => void;
  connectedSocialProfiles: SocialConnectedProfile[];
  setConnectedSocialProfiles: React.Dispatch<React.SetStateAction<SocialConnectedProfile[]>>;
  connectedSocialProfilesFetched: boolean;
  setConnectedSocialProfilesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  stripePromise: Promise<Stripe | null>;
  directStripePromise: Promise<Stripe | null> | null;
};

export const MarketplaceContext = createContext<MarketplaceContextProps>({
  marketplace: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMarketplace: () => {},
  hostname: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHostname: () => {},
  marketplaceSlug: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMarketplaceSlug: () => {},
  urlBase: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUrlBase: () => {},
  apexDomain: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setApexDomain: () => {},
  logoIsCircle: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLogoIsCircle: () => {},
  supportEmail: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSupportEmail: () => {},
  categories: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCategories: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchCategories: () => {},
  connectedSocialProfiles: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConnectedSocialProfiles: () => {},
  connectedSocialProfilesFetched: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConnectedSocialProfilesFetched: () => {},
  isLoading: false,
  stripePromise: Promise.resolve(null),
  directStripePromise: null,
});

interface MarketplaceProviderProps {
  children: React.ReactNode;
  marketplaceProvider: MarketplaceInfo;
}

export const MarketplaceContextProvider = ({
  children,
  marketplaceProvider,
}: MarketplaceProviderProps): React.ReactElement | null => {
  const showToast = useSingleToast();
  const [marketplace, setMarketplace] = useState<MarketplaceInfo | null>(marketplaceProvider);
  const [categories, setCategories] = useState<BasicCategory[]>([]);
  const [connectedSocialProfiles, setConnectedSocialProfiles] = useState<SocialConnectedProfile[]>(
    [],
  );
  const [connectedSocialProfilesFetched, setConnectedSocialProfilesFetched] =
    useState<boolean>(false);
  const [hostname, setHostname] = useState<string | null>(
    typeof window !== 'undefined' ? window.location.hostname : '',
  );
  const [marketplaceSlug, setMarketplaceSlug] = useState<string | null>(
    typeof window !== 'undefined' ? window.location.hostname.split('.')[0] : '',
  );
  const [apexDomain, setApexDomain] = useState<string | null>(null);
  const [logoIsCircle, setLogoIsCircle] = useState<boolean>(
    marketplace?.functional?.logo_is_circle || false,
  );
  const [supportEmail, setSupportEmail] = useState<string | null>(null);
  const { api } = useRequest();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [urlBase, setUrlBase] = useState('');
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(Promise.resolve(null));
  const [directStripePromise, setDirectStripePromise] = useState<Promise<Stripe | null> | null>(
    null,
  );

  const createStripePromise = (marketplace: MarketplaceInfo) => {
    const marketplaceId = marketplace?.id;
    if (!marketplace.functional) {
      Sentry.captureException(
        new Error(`Marketplace functional does not exist in createStripePromise: ${marketplaceId}`),
        { extra: { marketplaceId } },
      );
    }
    const stripeConnectConfig = marketplace.functional?.stripe_connect_config;
    // for Stripe Connect marketplaces we need to include this for all direct payments.
    // Peer-to-peer transactions are not direct, so we don't pass the ID for those.
    let clientAccountId: string | undefined = undefined;
    if (stripeConnectConfig?.client_account_id && stripeConnectConfig?.status === 'active') {
      clientAccountId = stripeConnectConfig.client_account_id;
    }

    // Create a retry mechanism for loading Stripe
    const loadStripeWithRetry = async (
      retries = 3,
      delay = 1000,
    ): Promise<Promise<Stripe | null>> => {
      for (let attempt = 0; attempt < retries; attempt++) {
        try {
          const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || '', {
            stripeAccount: clientAccountId,
          });
          return stripePromise;
        } catch (error) {
          console.error(`Stripe load attempt ${attempt + 1} failed:`, error);

          // If this is the last attempt, report to Sentry
          if (attempt === retries - 1) {
            Sentry.captureException(
              new Error(
                `Failed to load Stripe after ${retries} attempts for ${marketplaceId} - ${clientAccountId}`,
              ),
              {
                extra: {
                  marketplaceId,
                  clientAccountId,
                  error: error instanceof Error ? error.message : String(error),
                },
              },
            );
            // Return a rejected promise to indicate failure
            return Promise.reject(error);
          }

          // Wait before retrying
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }
      // This should never be reached due to the for loop, but TypeScript needs it
      return Promise.reject(new Error('Failed to load Stripe'));
    };

    // Try to load Stripe with retry logic
    loadStripeWithRetry()
      .then((promiseStripe) => {
        setStripePromise(promiseStripe);
      })
      .catch((error) => {
        // Set a rejected promise to indicate failure
        setStripePromise(Promise.reject(error));
      });

    if (clientAccountId) {
      // we need a second Stripe connection to handle a connected account (clientAccountId) running
      // a peer-to-peer transaction (direct)
      loadStripeWithRetry()
        .then((promiseDirectStripe) => {
          setDirectStripePromise(promiseDirectStripe);
        })
        .catch((error) => {
          // Set a rejected promise to indicate failure
          setDirectStripePromise(Promise.reject(error));
        });
    }
  };

  const createSupportEmail = (marketplace: MarketplaceInfo) => {
    // if this is set in feature config, use as an override. Outgoing customer support
    // emails will always use the generated email logic and ignore this mailto email.
    let email = marketplace?.functional?.mailto;
    if (!email) {
      // default email for non-apex marketplaces
      email = `support+${marketplace.slug}@loma.tech`;

      if (
        marketplace.domain &&
        marketplace.domain.config &&
        marketplace.domain.status === 'active' &&
        marketplace.domain.email_status === 'active'
      ) {
        // email domain can be set differently from the main domain
        // enables support@site.com vs support@market.site.com
        const emailDomain = marketplace.domain.config.email_domain || marketplace.domain.domain;
        // can also customize the email account. Default is "support"
        const supportEmailAccount = marketplace.domain.config.support_email || 'support';
        email = `${supportEmailAccount}@${emailDomain}`;
      }
    }
    setSupportEmail(email);
  };

  useEffect(() => {
    const fetchMarketplace = async (hostname: string) => {
      try {
        // Only try localStorage if we're in the browser
        if (typeof window !== 'undefined') {
          try {
            const cached = localStorage.getItem(`marketplace-${hostname}`);

            if (cached) {
              const { data, timestamp } = JSON.parse(cached);
              const isExpired = Date.now() - timestamp > 5 * 60 * 1000; // 5 minutes

              if (!isExpired) {
                setMarketplace(data);
                setMarketplaceSlug(data.slug);
                handleMarketplaceData(data);
                return;
              }
            }
          } catch (error) {
            console.warn('Unable to access localStorage for marketplace cache:', error);
            // Continue with API call if localStorage fails
          }
        }

        const data = await api.marketplace.hostname.get(hostname);

        // Only attempt caching if we're in the browser
        if (typeof window !== 'undefined') {
          try {
            const cacheData = {
              data,
              timestamp: Date.now(),
            };
            localStorage.setItem(`marketplace-${hostname}`, JSON.stringify(cacheData));
          } catch (error) {
            console.warn('Unable to save marketplace to localStorage:', error);
            Sentry.captureException(error);
          }
        }

        setMarketplace(data);
        setMarketplaceSlug(data.slug);
        handleMarketplaceData(data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const handleMarketplaceData = (data: MarketplaceInfo) => {
      if (data.domain && data.domain.status === 'active') {
        setApexDomain(data.domain.domain);
        setUrlBase(`https://${data.domain.domain}`);
      } else {
        const pubHost = new URL(process.env.NEXT_PUBLIC_HOST ?? '');
        const host = pubHost.hostname;
        const port = pubHost.port ? ':' + pubHost.port : '';
        setUrlBase(`${pubHost.protocol}//${data.slug}.${host}${port}`);
      }
      setLogoIsCircle(data.functional?.logo_is_circle ?? false);
      createSupportEmail(data);
      createStripePromise(data);
    };

    if (typeof window !== 'undefined' && urlBase === '') {
      if (marketplaceProvider) {
        setHostname(window.location.hostname);
        setMarketplace(marketplaceProvider);
        setMarketplaceSlug(marketplaceProvider.slug);
        handleMarketplaceData(marketplaceProvider);

        // Also cache the provided data
        try {
          localStorage.setItem(
            `marketplace-${window.location.hostname}`,
            JSON.stringify({
              data: marketplaceProvider,
              timestamp: Date.now(),
            }),
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        const hostname = window.location.hostname;
        setHostname(hostname);
        fetchMarketplace(hostname);
      }
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    if (!marketplace?.id) return;
    if (isLoading) return;
    if (!isAuthenticated) return;

    try {
      setIsLoading(true);
      const response = await api.marketplace.categories.list(marketplace.id);
      setCategories(response);
    } catch (error) {
      showToast({
        title:
          'There was a problem fetching categories. Please try again later or contact support.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplace?.id, isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      marketplace,
      setMarketplace,
      hostname,
      setHostname,
      marketplaceSlug,
      setMarketplaceSlug,
      urlBase,
      setUrlBase,
      apexDomain,
      setApexDomain,
      logoIsCircle,
      setLogoIsCircle,
      supportEmail,
      setSupportEmail,
      categories,
      setCategories,
      fetchCategories,
      connectedSocialProfiles,
      setConnectedSocialProfiles,
      connectedSocialProfilesFetched,
      setConnectedSocialProfilesFetched,
      isLoading,
      stripePromise,
      directStripePromise,
    }),
    [
      marketplace,
      setMarketplace,
      hostname,
      setHostname,
      marketplaceSlug,
      setMarketplaceSlug,
      urlBase,
      setUrlBase,
      apexDomain,
      setApexDomain,
      logoIsCircle,
      setLogoIsCircle,
      supportEmail,
      setSupportEmail,
      categories,
      setCategories,
      fetchCategories,
      connectedSocialProfiles,
      setConnectedSocialProfiles,
      connectedSocialProfilesFetched,
      setConnectedSocialProfilesFetched,
      isLoading,
      stripePromise,
      directStripePromise,
    ],
  );

  return <MarketplaceContext.Provider value={contextValue}>{children}</MarketplaceContext.Provider>;
};

export const useMarketplaceContext = () => useContext(MarketplaceContext);
